import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import PointChart from "@/views/analysis/PointChart.vue";
import PointBalanceChart from "@/views/analysis/PointBalanceChart.vue";
import PointOriginalListGet from "@/store/analysis/point-original-list/get";
import Admin from "@/store/admin/admin";
import { PointOriginalListGetRequest } from "@/api/analysis/point-original-list/request";
import PointCompleteChart from "@/views/analysis/PointCompleteChart.vue";
import { PointBalanceUserCSVGetRequest } from "@/api/analysis/point-balance-user-csv/request";
import PointBalanceGet from "@/store/analysis/point-balance/get";
import * as PointOriginalListCSV from "@/api/analysis/point-original-list-csv";
import { isSuccess } from "@/api/response";

export interface PointOriginalInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    PointChart,
    PointBalanceChart,
    PointCompleteChart
  }
})
export default class AnalysisPointOriginal extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "店舗ポイントカード";
  headingSub = "Point card of shop";
  breadCrumbs = [{ text: "店舗ポイントカード", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 }
  ];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedPointOriginalData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  pointInputOptions: PointOriginalInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: 1,
    selectShop: null
  };

  get pointOriginalItems() {
    return PointOriginalListGet.getItems;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.pointInputOptions.startDate);
  }
  set startDateForView(date) {
    this.pointInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.pointInputOptions.endDate);
  }
  set endDateForView(date) {
    this.pointInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  get tableHeaders() {
    return [
      { text: "日付", value: "date" },
      { text: "利用数", value: "value" }
    ];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.pointInputOptions.selectShop = ShopGet.getItems[0];
      this.fetchPointOriginalList();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await PointOriginalListGet.clearResponse();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchPointOriginalList(): Promise<boolean> {
    this.isLoadedPointOriginalData = false;
    await Flash.clear();
    const PointOriginalListGetRequest: PointOriginalListGetRequest = {
      startDate: this.pointInputOptions.startDate,
      endDate: this.pointInputOptions.endDate,
      viewType: this.pointInputOptions.viewType,
      searchShopId:
        this.pointInputOptions.selectShop != null
          ? Number(this.pointInputOptions.selectShop.id)
          : null
    };
    await PointOriginalListGet.get(PointOriginalListGetRequest);
    if (!PointOriginalListGet.isSuccess) {
      await Flash.setErrorNow({
        message: PointOriginalListGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPointOriginalData = true;
    return PointOriginalListGet.isSuccess;
  }

  async fetchPointOriginalListCSV() {
    if (this.pointInputOptions.selectShop == null) {
      return false;
    }

    await Flash.clear();
    const pointOriginalListCSVGetRequest: PointOriginalListGetRequest = {
      startDate: this.pointInputOptions.startDate,
      endDate: this.pointInputOptions.endDate,
      viewType: this.pointInputOptions.viewType,
      searchShopId: Number(this.pointInputOptions.selectShop.id)
    };

    const pointOriginalListCSVGetResponse = await PointOriginalListCSV.get(
      pointOriginalListCSVGetRequest
    );
    if (!isSuccess(pointOriginalListCSVGetResponse)) {
      await Flash.setErrorNow({
        message: pointOriginalListCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.saveCSV(
      pointOriginalListCSVGetResponse.results.csvString,
      pointOriginalListCSVGetResponse.results.fileName
    );
  }
}
